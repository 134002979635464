html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-appearance: none;
}
body {
  margin: 0;

  font-family: "AvenirLight" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
* {
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
}
html {
  -webkit-text-size-adjust: none;
}

code {
  font-family: "AvenirLight", sans-serif;
}

.wrapper {  height:100%; display:flex;  justify-content: center;  align-items: center;  overflow:scroll;}
.item {   flex:1; width: 100%; max-width: 1024px; }
.width {
  width: 1024px;
  height: 768px;
}
/*********************/
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 90%;
  height: 35px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgb(230, 235, 243) 12%,
    rgba(42, 125, 182, 0.16290266106442575) 3%,
    rgba(26, 65, 130, 1) 93%,
    rgba(9, 51, 121, 1) 100%
  );
  outline: none;
  opacity: 0.7;
  border-radius: 20px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border: 0.2px solid gray;
  opacity: 1;
}

.slider:hover {
  opacity: 0.7;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 45px;
  height: 45px;
  background: #cac7c1;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}
/***************************/

/***************************/
.item.flex-form {
  padding: 55px;
  color: #999;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
}

h3 {
  font-size: 15px;
  color: #999 !important;
  font-weight: normal;
}

.flex-form h3 {
  margin: 40px 0 0 0px;
  position: absolute;
  z-index: 999;
  top: 0;
}

.flex-outer {
  list-style-type: none;
  padding: 0px 5vw;
  float: left;
  width: 50%;
}
.flex-outer li {
}
.flex-inner {
  padding: 0 8px;
}
.flex-outer > li:not(:last-child) {
  margin-bottom: 90px;
}
.flex-outer > li {
  text-align: center;
}

.flex-outer li label,
.flex-outer li p {
  padding: 8px;
  font-weight: 300;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}
.flex-outer > li > label,
.flex-outer li p {
  flex: 1 0 120px;
  max-width: 220px;
}
.flex-outer > li > label + *,
.flex-inner {
  flex: 1 0 220px;
}
.flex-outer li p {
  margin: 0;
}
.flex-outer li input:not([type="checkbox"]) {
  padding: 15px;
  /*border: 0.2px solid #999999;*/
  border-radius: 10px;
  width: 34vw;
  height: 53px;
  text-align: center;
  font-family: "AvenirLight";
  font-size: 13px;
  color: #515151;
  max-width: 100%;
}
.flex-outer li textarea {
  padding: 15px;
  /*border: 0.2px solid #999999;*/
  border-radius: 10px;
  width: 34vw;
  height: 113px !important;
  text-align: left;
  margin-bottom: 22px;
  font-family: "AvenirLight";
  font-size: 13px;
  color: #515151;
}
.flex-outer li button {
  margin-left: auto;
  padding: 8px 16px;
  border: none;
  background: #333;
  color: #f2f2f2;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  border-radius: 2px;
}
.flex-inner li {
  width: 100px;
}

/***************************/

.box {
  height: 90%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.box h2{ font-size:30px;font-family: 'AvenirMedium';}

.dreamtext {
  font-size: 22px;
  color: #808080;
  padding: 0 0 0 60px;
  letter-spacing: -0.75px;
}

.vid {
  position: relative;
}
.vid .vid-container {
  width: 25%;
  position: relative;
  float: left;
  height: 768px;
}
.myVideo {
  width: 100%; /*height: 100vh; */
  z-index: 0;
}
.myVideom {
  width: 100%;
  display: none;
  z-index: 0;
}
.vid .vid-container > div {
  position: absolute;
  top: 40%;
  left: 0;
  color: #fff;
  padding: 0 15%;
  text-align: left;
  line-height: 26px;
  font-size: 22px;
    letter-spacing: -1.5px;
}

.arrow-dw {
  position: absolute;
  bottom: 29px;
  left: 0;
  right: 0;
  text-align: center;
}

.item.fit {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
}
.fit .fit-container {
  /*width: 19.9%;
  position: relative;
  float: left;*/
}
.fit .fit-container2 {
 /* width: 31%;
  margin: 0 1%;*/
}
.myfit {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.fit .fit-container > div {
  position: absolute;
  top: 40%;
  left: 0;
  color: #fff;
  padding: 0 10%;
  text-align: left;
  line-height: 26px;
  font-size: 23px;
}

.BrainhubCarousel {flex-direction: column; /*position: relative;*/}
.BrainhubCarousel__arrows.BrainhubCarousel__arrowLeft {order:2; position: absolute; bottom: 5%; left: 45%; z-index: 999;}
.BrainhubCarousel__arrows.BrainhubCarousel__arrowRight {order:3; position: absolute; bottom: 5%; right: 45%;}
.BrainhubCarousel__arrows,.BrainhubCarousel__arrows:disabled {  background-color: #fff0 !important; padding: 25px !important ;}
.BrainhubCarousel__arrows span {  border-color: #999999 !important; border-width: 0.2px 0.2px 0 0 !important;  padding: 14px !important;}
.BrainhubCarousel__arrows:disabled span {border-color: #ccc !important;}

.fit h3 {
  margin: 40px 0;
  padding-left: 55px;
  position: absolute;
  top: 0;
}
.fit .fit-container input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.fit .fit-container input[type="radio"]:checked + label,
.Checked + label {
  background: black;
  color: #fff;
}
.fit .fit-container img {
  width: 100%;
}
.fit .fit-container label {
  padding: 5px;
  border: 0.2px solid #999;
  cursor: pointer;
  z-index: 90;
  color: #999;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
  width: 132px;
  height: 45px;
  line-height: 34px;
}
.fit .fit-container button {
  padding: 5px;
  margin: 0 auto;
  border: 0.2px solid #999;
  cursor: pointer;
  z-index: 90;
  color: #999;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
  width: 125px;
  height: 45px;
  line-height: 34px;
  display: block;

}

.fit .fit-container button:hover {
  background: #999;
  color: #fff;
}
.fit .fit-container.selected-button button {
  background: #000;
  color: #fff;
}
.item.inseam {
  position: relative;
}
.inseam h3 {
  margin: 40px 0;
  padding-left: 55px;
  position: absolute;
  top: 0;
}
.inseam-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 30px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 28px !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0.2px solid #999 !important;
  border-radius: 10px !important;
}
.ant-select-arrow {
  display: none !important;
}
.ant-select-selection-placeholder {
  color: #999 !important;
  font-size: 13px !important;
}

.wash .wash-container {
  width: 17.86%;
  position: relative;
  float: left;
}
.wash .wash-container img {
  width: 100%;
  height: 100vh;
}

.wash .wash-container span {
  display: block;
}
.wash h3 {
  margin: 40px 0 0 55px;
  position: absolute;
  z-index: 999;
}
.wash .wash-container span {
  padding-top: /*370px*/ 48vh;
  border: 0px solid #999;
  cursor: pointer;
  z-index: 90;
  color: #fff;
  font-size: 12px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.wash .wash-container:first-child span {
  color: #999;
}
.wash .wash-container.wash1 label {
  color: #999;
}
.wash-select-button {
  padding: 5px;
  background: transparent;
  border: 0.2px solid rgb(242, 242, 242);
  cursor: pointer;
  color: rgb(242, 242, 242);
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
  width: 132px;
  height: 45px;
  line-height: 34px;
  margin-top: 20px;
}
.wash .wash-container.selected-wash {
  background-color: #b3b3b3;
  opacity: 50%;
}
.unSelected-wash {
  background-color: #b3b3b3;
  opacity: 50%;
  overflow: auto;
}
.unSelected-wash-2 {
  background-color: #b3b3b3;
  opacity: 0.5;
}
.item.fabric {
  background: #f2f2f2;
}
.fabric h3 {
  margin-bottom: 40px;
  padding: 55px;
  position: absolute;
}

.slider {
  -webkit-appearance: none !important;
  outline: none !important;
  background: linear-gradient(
    90deg,
    rgb(242 242 242) 5%,
    rgb(242 242 242) 3%,
    #3e6995 93%,
    #3e6995 100%
  ) !important;
  border: 0 !important;
  width: 457px !important;
  height: 30px !important;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 30px !important;
  height: 30px !important;
  background: #ccc !important;
  cursor: pointer !important;
  /*border: 0.2px solid rgb(180, 180, 180)*/
}
.slider::-moz-range-thumb {
  width: 30px !important;
  height: 30px !important;
  background: #ccc !important;
  cursor: pointer !important;
}

.rigid {
  margin-top: 5px;
  margin-left: 10px;
  color: #999;
  font-weight: 500;
  font-size: 15px;
}
.stretch {
  margin-top: 5px;
  margin-right: 10px;
  color: #999;
  font-weight: 500;
  font-size: 15px;
}

.item.hardware {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(242, 242, 242, 1) 7%,
    rgba(136, 146, 168, 1) 80%
  );
}
.hardware .hardware-container {
  width: 33.33%;
  position: relative;
  float: left;
  padding: 0 65px;
  display: flex;
  flex-direction: column;
}
.myhardware {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.hardware .hardware-container img {
  width: 100%;
}

.hardware h3 {
  padding: 50px 0 0 55px;
  position: absolute;
}
.hardware .hardware-container button {
  padding: 5px;
  background: transparent;
  border: 0.2px solid #f2f2f2;
  cursor: pointer;
  z-index: 90;
  color: #f2f2f2;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
  width: 200px;
  height: 45px;
  line-height: 34px;
  display: block;
  margin: 40px auto 0;
}
.hardware .hardware-container button:hover {
  background: #f2f2f2;
  color: #b3b3b3;
}
.hardware .hardware-container.selected-hardware button {
  background: #f2f2f2;
  color: #b3b3b3;
}
.item.stitching {
  background: linear-gradient(
    0deg,
    rgba(40, 50, 60, 1) 0%,
    rgba(136, 147, 168, 1) 80%
  );
}
.stitching h3 {
  padding: 50px 0 0 55px;
  color: #fff !important;
  position: absolute;
}
.stitching .stitching-container {
  width: 50%;
  position: relative;
  float: left;
}

.stitching .stitching-container img {
  width: 100%;
}
.stitching-container img.hover {
  display: none;
}

.stitching .stitching-container span {
  color: #fff;
  font-size: 15px;
}
.stitching .stitching-container:hover img.mystitching {
  display: none;
}
.stitching .stitching-container:hover img.hover {
  display: block;
}

.stitching .stitching-container.selected-stitching img.mystitching {
  display: none;
}

.stitching .stitching-container.selected-stitching img.hover {
  display: block;
}

.item.hem {
  background: linear-gradient(
    0deg,
    rgba(62, 70, 79, 1) 7%,
    rgba(40, 50, 60, 1) 80%
  );
}
.hem .hem-container {
  /*width: 25%;
  position: relative;
  float: left;
  padding: 0 3vw;
  */
}
.hem .hems {
  display: flex;
  justify-content: center;
  gap: 75px;
}
.myhem {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.hem h3 {
  padding: 50px 0 0 55px;
  color: #fff;
  position: absolute;
}
.hem .hem-container button {
  padding: 5px;
  background: transparent;
  border: 0.2px solid #f2f2f2;
  cursor: pointer;
  z-index: 90;
  color: #f2f2f2;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
  width: 16vw;
  height: 55px;
  line-height: 34px;
  max-width: 170px;
}
.hem .hem-container button:hover {
  background: #f2f2f2;
  color: #b3b3b3;
}
.item.hem .ant-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: flex-start;
}
.hem .hem-container.selected-hem button {
  background: #f2f2f2;
  color: #b3b3b3;
}

.item.additional-notes {
  background: linear-gradient(
    0deg,
    rgba(101, 107, 113, 1) 7%,
    rgba(62, 70, 79, 1) 80%
  );
}
.additional-notes textarea {
  padding: 15px;
  border: 0.2px solid #999999;
  border-radius: 10px;
  width: 480px;
  height: 360px !important;
  text-align: left;
  margin-bottom: 14px;
  font-family: "AvenirLight";
  font-size: 13px;
  color: #fff;
  background: transparent;
}
.ant-input-affix-wrapper {
  width: auto !important;
  background-color: transparent !important;
}

.item.formsubmit {
  background: linear-gradient(
    0deg,
    rgba(208, 208, 208, 1) 7%,
    rgba(101, 107, 113, 1) 80%
  );
  position: relative;
}
.item.formsubmit .submit-btndiv {
  position: absolute;
  bottom: 0px;
  width: 70%;
  text-align: center;
}
.item.formsubmit .submit-btndiv button {
  border-radius: 10px 10px 0px 0px;
  height: 77px;
  background-color: black;
  color: white;
  width: 362px;
  font-size: 13px;
}
.table {
  display: grid;
  grid-template-columns: 1fr 80%;
  /*width: 70vw;*/
  border: 0.2px solid #fff;
  border-radius: 10px;
  max-width: 900px;
  min-width: 700px;
}
.table > div {
  padding: 16px 22px;
  border-bottom: 0.2px solid #fff;
  border-right: 0px solid #fff;
  color: #fff;
  font-size: 13px;
}
.table > div:nth-child(2n + 1) {
  border-right: 0.2px solid #fff;
}
.table > div:last-child, .table > div:nth-last-child(2) {
  border-bottom: 0;
  padding-top: 50px;
  padding-bottom: 50px;
}.table > div:nth-last-child(2) {
  padding-top: 50px;
  padding-bottom: 50px;
}

/***************************/

.ant-select-dropdown {
  text-align: center !important;
}

.ant-input:placeholder-shown {
  background-color: inherit;
}
.ant-table {
  background: inherit !important;
}

/* CSS By Iftikhar */

.sth-1 {
  width: 423px;
  height: 350px;
  background-image: url("../images/stitch-tonal-1.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
}
.sth-1.sth-2 {
  float: right;
  background-image: url("../images/stitch-contrast-i.png");
}
.sth-1:hover,
.selected-tonal .sth-1 {
  background-image: url("../images/stitch-tonal-hover-i.png");
}

.sth-2:hover,
.selected-contrast .sth-1 {
  background-image: url("../images/stitch-contrast-hover-i.png");
}



@media only screen and (max-width: 768px) {
  .width {
    height: 1024px;
    width: 768px;
  }
  .dreamtext {
    padding: 0 0 0 0;
    text-align: center;
    width: 100%;
  }
  .vid .vid-container {
    height: 1024px;
  }
  .vid .vid-container > div {
    top: 46%;
    line-height: 22px;
    font-size: 18px;
  }
  .hardware .hardware-container {padding: 0 25px;}
  .hem .hems {  gap: 55px;  }
.sth-1{background-size: 100%; width: 350px;  height: 293px;}

  .myVideo {
    display: none;
  }
  .myVideom {
    display: block;
  }

  .fit .fit-container2 {
   /* width: 21%;
    margin: 0 0.5%;*/
  }

  
}
