.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgb(255,255,255);
    background: linear-gradient(175deg, rgba(255,255,255,1) 0%, rgba(73,97,96,1) 100%, rgba(24,147,176,1) 100%);
    /* background: -webkit-linear-gradient(63deg, #151515 5px, transparent 5px) 0 5px, -webkit-linear-gradient(243deg, #151515 5px, transparent 5px) 10px 0px, -webkit-linear-gradient(63deg, #222 5px, transparent 5px) 0px 10px, -webkit-linear-gradient(243deg, #222 5px, transparent 5px) 10px 5px, -webkit-linear-gradient(0deg, #1b1b1b 10px, transparent 10px), -webkit-linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background: -moz-linear-gradient(63deg, #151515 5px, transparent 5px) 0 5px, -moz-linear-gradient(243deg, #151515 5px, transparent 5px) 10px 0px, -moz-linear-gradient(63deg, #222 5px, transparent 5px) 0px 10px, -moz-linear-gradient(243deg, #222 5px, transparent 5px) 10px 5px, -moz-linear-gradient(0deg, #1b1b1b 10px, transparent 10px), -moz-linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background: -ms-linear-gradient(63deg, #151515 5px, transparent 5px) 0 5px, -ms-linear-gradient(243deg, #151515 5px, transparent 5px) 10px 0px, -ms-linear-gradient(63deg, #222 5px, transparent 5px) 0px 10px, -ms-linear-gradient(243deg, #222 5px, transparent 5px) 10px 5px, -ms-linear-gradient(0deg, #1b1b1b 10px, transparent 10px), -ms-linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background: -o-linear-gradient(63deg, #151515 5px, transparent 5px) 0 5px, -o-linear-gradient(243deg, #151515 5px, transparent 5px) 10px 0px, -o-linear-gradient(63deg, #222 5px, transparent 5px) 0px 10px, -o-linear-gradient(243deg, #222 5px, transparent 5px) 10px 5px, -o-linear-gradient(0deg, #1b1b1b 10px, transparent 10px), -o-linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background: linear-gradient(63deg, #151515 5px, transparent 5px) 0 5px, linear-gradient(243deg, #151515 5px, transparent 5px) 10px 0px, linear-gradient(63deg, #222 5px, transparent 5px) 0px 10px, linear-gradient(243deg, #222 5px, transparent 5px) 10px 5px, linear-gradient(0deg, #1b1b1b 10px, transparent 10px), linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background-size: 20px 20px;
    background-color: #131313; */
    
    /* background: #fafafa; */
    /* background: rgb(217,172,199); */
    /* background: #00308F;
    background: linear-gradient(0deg, rgba(217,172,199,1) 0%, rgba(144,141,182,1) 100%); */
}



.form{
    border: 1px solid rgb(218, 218, 218);
    width: 350px;
    background: #fff;
    box-shadow: rgba(73,97,96,1) 0px 5px 15px;
    border-radius: 5px;

}

.formHeading{
    padding: 20px 0 0 20px;
}

.formHeading h3{
    font-weight: 600;
    font-size: 1.5rem;
    color: #1b1b1b !important;
}

.formHeading p {
    color: #1b1b1b !important;
    font-weight: 500;
    font-size: 0.8rem;
}

.formBody p {
    line-height: 0;
    color: #082032;
    font-size: 0.9rem;
    font-weight: 400;
}

.formBody{
    padding: 10px 20px 25px 20px;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .form {
      width: 300px;
    }
  }


  .ant-form-item-explain.ant-form-item-explain-error{
      font-size: 10px !important;
      text-align: right;
  }
